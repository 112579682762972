// Toggles menu button on mobile
// Source: https://mdbootstrap.com/docs/jquery/navigation/hamburger-menu/
$ = jQuery;
$(document).ready(function ($) {

		// console.log("here2");
	$('.first-button').on('click', function () {
		
		$('.animated-icon1').toggleClass('open');
		// console.log('hi');
		// check if animated-icon1 has open class
		$('.first-button').addClass('shadow-none');
		$('.menu-item').last().css('border-bottom', 'none');
		if ( $('.animated-icon1').hasClass('open') ) {
			document.getElementById("navbarNav2").style.width = "100%";
			$('#navbarNav2').css('left', 0);
			$('.first-button').delay(270).queue(function(next){
				$(this).addClass('bg-transparent');
				next();
			});
			//remove scroll from body
			$('body').css('overflow-y', 'hidden');
		} else {
			// document.getElementById("navbarNav2").style.width = "0%";
			$('#navbarNav2').css('left', -10000);
			$('.first-button').removeClass('bg-transparent');
			//return scroll from body
			$('body').css('overflow-y', 'unset');
		}
	});
	// Get Height of window
	var documentHeight = $(document).height();
	// Minus height of footer
	var footerHeight = $('.footer').height();
	// Set this height to #btnwrp
	$('#btnwrp').height( documentHeight - footerHeight/2);


	$('#filter_year').on('change',function(){
        var optionText = $("#filter_year option:selected").val();
		var data = {
			action : "myfilter",
			security : ThemeJS.Variables.Ajax.nonce,
			year : optionText 
		};
		// Source: https://rudrastyh.com/wordpress/ajax-post-filters.html
		var filter = $('#filter');
		$('#posts').html('<div class="loader"></div>');
		$.ajax({
			url: ThemeJS.Variables.Ajax.url,
			data: data, // form data
			type: 'POST', // POST
			success:function(data){
				$('#posts').html(data); // insert data
				$('#pagination').hide(); // hide pagination
			}
		});
		return false;
    });

	// Hide border bottom for last element in Related Stories
	$('.related:last').css('border-bottom', 'none');

	// Add copy link on single post
	// Source: https://clipboardjs.com

	$('#copylink').eq(0).append($('<div>', {id: 'copy-link'}));
    var copyShare = $('#copy-link');

    $(copyShare).append($('<span>', { id: 'copy-span'}));// <i class="fad fa-copy"></i>
    $('#copy-span').addClass('fad fa-copy mr-2');
	$(copyShare).append($('<button>', { id: 'copy-link-btn'}));
    $('#copy-link button')
    .text('COPY LINK TO SHARE')
    .attr('data-clipboard-text', window.location.href)
	.addClass('border-0 p-0');
    
    var clipboard = new ClipboardJS('#copy-link button');
	clipboard.on('success', function(e) {
		// console.info('Action:', e.action);
		// console.info('Text:', e.text);
		// console.info('Trigger:', e.trigger);

		e.clearSelection();
	});

	clipboard.on('error', function(e) {
		console.error('Action:', e.action);
		console.error('Trigger:', e.trigger);
		console.log('error');
	});

	// Hide last blue div on Contact page
	$('.contact-page .blue-line:last').hide();

	// Toggle calss name in accordion on Our Expertise page  
	$('.accord-btn').click(function() {
		var $this = $(this);
		// when we click, we add 'act' class to all elements
		$('.accord-btn').removeClass('act');
		// next we remove 'act' class from target element
		$this.addClass('act');
		// we make target element 100% width
		// var elWidth = $this.closest('.row.no-gutters').width();
		// var elWidth = $(window).width();
// console.log(elWidth);
// console.log($this.width());
		// $this.css('width', elWidth * 0.69026); //210px
		var elWidth = $(window).width();
		var wdth = 0;
		if ( elWidth < 576 ) {
			wdth = elWidth - 70;
		} else if ( elWidth < 585 ) {
			wdth = elWidth - 118;
		} else if ( elWidth < 768 ) {
			wdth = elWidth - 296;
		} else if ( elWidth == 768 ) {
			wdth = elwidth - 118;
		}

		$this.css('width', wdth); //210px
	});

	// Set width of accordion if window is resized
	$(window).resize(function(){
		var elWidth = $(window).width();
		var wdth = 0;
		if ( elWidth < 576 ) {
			wdth = elWidth - 70;
		} else if ( elWidth < 585 ) {
			wdth = elWidth - 118;
		} else if ( elWidth < 768 ) {
			wdth = elWidth - 296;
		} else if ( elWidth == 768 ) {
			wdth = elwidth - 118;
		}
		$('.accord-btn').css('width', wdth);
	});

	// Show tooltip if link was copied
	//#tooltiptext
	$('#copylink').click(function() {
		$('#tooltiptext').removeClass('d-none');
		$('#tooltiptext').css('width', 150);
		$('#tooltiptext').addClass('d-inline-block');
	});

	// Add smooth scroll
	
	
});